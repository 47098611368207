<template>
  <div class="container-fluid">
    <d-body-top-bar :title="$t('admin.club_detail.title')">
      <div slot="tree">
        <label class="pointer link-area ml-1"
               @click="$router.push({ name: 'clubs'})"
        >
          {{ $t('admin.clubs.list') }} /
        </label>
        <label
          class="pointer inactive-tree link-area ml-1"
        >
          {{ $t('admin.club_detail.detail_club') }}
        </label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <b-row no-gutters>
        <b-col v-if="tabSelected !== 'importclient' " :cols="mobileCol (3)">
          <b-card>
            <template v-if="club">
              <div class="container-fluid text-center">
                <b-row no-gutters>
                  <b-col>
                    <h4 class="title-top"> {{ club.name }} </h4>
                  </b-col>
                </b-row>
              </div>
              <div class="row">
                <div :class="getTitleClass">
                  <h6 class="mt-4 title-perso-infs">
                    {{ $t('admin.club_detail.titles.informations') }}
                  </h6>
                </div>
                <div :class="getBorderClass" class="border-bottom-title">
                </div>
                <div class="container-fluid">
                  <b-row :class="'mt-2 '">
                    <b-col class="right-general-infs text-right" size="12">
                      <template v-for="address in club.address">
                        {{ address }}
                      </template>
                    </b-col>
                  </b-row>
                  <b-row :class="'mt-2 '">
                    <b-col class="right-general-infs text-right" size="12">{{ club.city }}({{ club.zipCode }}) -
                      {{ club.country }}
                    </b-col>
                  </b-row>
                  <b-row :class="'mt-2 '">
                    <b-col class="right-general-infs text-right" size="12">
                      {{ club.fixPhoneNumber }} - {{ club.mobilePhoneNumber }}
                    </b-col>
                  </b-row>
                  <b-row :class="'mt-2 '+getMargins">
                    <b-col class="right-general-infs text-right" size="12">{{ club.email }}</b-col>
                  </b-row>
                  <b-row :class="'mt-2 '+getMargins">
                    <b-col class="right-general-infs text-right" size="12"><b>{{ $t('admin.clubs.sms-sold') }} :</b>
                      {{ club.smsAccountBalance }}
                    </b-col>
                  </b-row>
                  <b-row :class="'mt-2 '+getMargins">
                    <div class="d-btn-small btn text-center d-btn-danger btn-full-width" v-on:click="updateClub()">
                      {{ $t('admin.clubs.update-club') }}
                    </div>
                  </b-row>
                  <b-row :class="'mt-2 '+getMargins">
                    <div class="d-btn-small btn text-center d-btn-primary btn-full-width" v-on:click="duplicateClub()">
                      {{ $t('admin.clubs.duplicate-config-club') }}
                    </div>
                  </b-row>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>
        <b-col :class="tabSelected === 'importclient' ? '' : 'pl-3'" :cols="tabSelected === 'importclient' ? mobileCol (12) : mobileCol (9)">
          <top v-if="club" :club="club"></top>
          <b-card>
            <d-body-nav-bar
              :tabs="arrayTabs"
              @tab-changed="onTabChanged"
            />
            <users-club v-if="club && this.tabSelected === 'users'" :club="club"/>
            <stripe-club v-if="club && this.tabSelected === 'stripe'" :club="club"/>
            <config-mb v-if="club && this.tabSelected === 'configmb'" :club="club" :config="config" @update-config="updateConfig" />
            <nextore-config v-if="club && tabSelected === 'caisse'" :club="club"/>
            <import-client v-if="club && this.tabSelected === 'importclient'" :club="club"/>
            <settings v-if="club && this.tabSelected === 'settings'" :club="club" :config="config" />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { getClub, getConfigMbClub, putClub } from "@api/services/club/club.api";
import NextoreConfig from "@views/clubs/detail/NextoreConfig";

export default {
  components: {
    NextoreConfig,
    UsersClub: () => import('@/views/clubs/detail/UsersClub'),
    Top: () => import('@/views/dashboard/content/Top'),
    StripeClub: () => import('@/views/clubs/detail/StripeClub'),
    ConfigMb: () => import('@/views/clubs/detail/ConfigMb'),
    ImportClient: () => import('@views/clubs/detail/Import'),
    settings: () => import('@/views/clubs/detail/Settings'),
  },
  data: () => ({
    innerWidth: 1600,
    subscriptionCards: [],
    club: null,
    config: {
      id: null,
      '@id': null,
      name: '',
      branchApiKey: '',
      oneSignalApiAuthKey: '',
      oneSignalApiId: '',
      appVersion: '',
      appleAppLink: '',
      googleAppLink: '',
      smsSenderName: ''
    },
    tabSelected: 'users',
    arrayTabs: [
      {name: 'admin.clubs.tab.users', active: true, id: 'users'},
      {name: 'admin.clubs.tab.stripe', active: false, id: 'stripe'},
      {name: 'admin.clubs.tab.orangemoney', active: false, id: 'orangemoney'},
      {name: 'admin.clubs.tab.caisse', active: false, id: 'caisse'},
      {name: 'admin.clubs.tab.configmb', active: false, id: 'configmb'},
      {name: 'admin.clubs.tab.import', active: false, id: 'importclient'},
      {name: 'admin.clubs.tab.settings', active: false, id: 'settings'},
    ]
  }),
  methods: {
    updateClub() {
      this.$router.push('/clubs/update/' + this.club.id);
    },
    duplicateClub() {
      this.$router.push('/clubs/duplicate/' + this.club.id);
    },
    mobileCol(x) {
      return this.innerWidth <= 991 ? '12' : x;
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    loadClub() {
      return getClub('/clubs/' + this.$route.params.id).then((response) => {
        this.club = response.data;
      });
    },
    updateConfig(config) {
      this.config = config.data;
      if(this.club.doinsportWebsiteUrl) {
        this.updateWebsiteUrl();
      }
    },
    updateWebsiteUrl() {
      return putClub(this.club.id, { doinsportWebsiteUrl: this.club.doinsportWebsiteUrl }).catch(err => {
        if (err.data.violations) {
          err.data.violations.forEach(item => {
            this.$vs.notify({ time: 10000, title: item.propertyPath ,text:  item.message, color: 'warning', position: 'top-right'});
          });
        } else {
          this.$vs.notify({ time: 10000, title: err.data["hydra:title"] ,text:  err.data["hydra:description"], color: 'warning', position: 'top-right'});

        }
        console.error(err);
      });
    },
    onTabChanged(tab) {
      this.tabSelected = tab.id;
    }
  },
  created() {
    this.innerWidth = window.innerWidth;
    this.loadClub().then(() => {
      getConfigMbClub(this.club.id).then(res => {
        if (res.data) {
          this.config = res.data;
        }
      });
    });
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  computed: {
    age() {
      return this.client.birthDate ? this.$moment().diff(this.$moment(this.client.birthDate), 'years') : this.$t('general.actions.undefined');
    },
    getMargins() {
      if (this.innerWidth < 1200) {
        return '';
      } else {
        return 'ml-3 mr-2';
      }
    },
    getTitleClass() {
      if (this.innerWidth > 1500 && this.innerWidth < 1850) {
        return 'col-md-10'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1500) {
        return 'col-md-9'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-10'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-8'
      }
    },
    getBorderClass() {
      if (this.innerWidth > 1500 && this.innerWidth < 1850) {
        return 'col-md-2'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1500) {
        return 'col-md-3'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-2'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-4'
      }

    }
  }
}
</script>
<style scoped>
.title-perso-infs {
  font: normal normal bold 21px/13px Source Sans Pro;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}


@media only screen and (max-width: 791px) {
  .title-perso-infs {
    font: normal normal bold 10px Source Sans Pro;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 991px) {
  .title-perso-infs {
    font: normal normal bold 14px Source Sans Pro;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 1527px) {
  .title-perso-infs {
    font: normal normal bold 18px Source Sans Pro;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

.border-bottom-title {
  border-bottom: 2px solid #EDF0F3;
}

.right-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 0.5;
}

</style>
